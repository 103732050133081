import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  ListItemText,
  Checkbox,
  Menu,
  IconButton,
  TableCell,
  Typography,
  TableFooter,
  Tooltip,
  Table,
  TableBody,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Fade,
  CircularProgress,
} from "@mui/material";
import PendingIcon from '@mui/icons-material/Pending';
import DeleteIcon from '@mui/icons-material/Delete';
import TextsmsIcon from '@mui/icons-material/Textsms';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import JsonData from "../client/data.json";
import ViewModal from "../client/viewModal";
import InfoIcon from "@mui/icons-material/Info";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PeopleIcon from "@mui/icons-material/People";
import EmailIcon from "@mui/icons-material/Email";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InventoryIcon from "@mui/icons-material/Inventory";
import TuneIcon from "@mui/icons-material/Tune";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import MarkerImage from "../../assets/images/marker.png";
import "leaflet/dist/leaflet.css";
import Client from "../client/client";
import EditClientModal from "../client/edit/Modal";
import moment from "moment";
import { useToken } from "../../context/TokenContext";
import dotnetApiClient from "../../config/dotnetApiClient";
import EmptyState from "../EmptyState/EmptyState";
import FooterComponent from "../footer";
import ChatComponent from "../ChatComponent/ChatComponent";
import ChatDialog from './ChatDialog';
import { logErrorFunction, logFunction } from "../../helper/service";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ClientType {
  id: number;
  name: string;
  email: string;
  phone: string;
  progressPercentage: number;
  lastLoginDtUtc: string;
  unreadMessageCount?: number
}

interface FilterableColumnTableProps {
  clients: Client[];
}

export const FilterableColumnTable: React.FC<FilterableColumnTableProps> = ({ clients }) => {
  const navigate = useNavigate();
  const ITEM_HEIGHT = 48;
  const tempDataJson: any = JSON.parse(
    localStorage.getItem("dataJson") || "{}"
  );
  const lawyerData: any = JSON.parse(
    localStorage.getItem("lawyerData") || "{}"
  );
  const [setDataJsonTemp] = useState<any>(tempDataJson);
  const [uiUserData, setUiUserData] = useState<ClientType[]>([]);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tabValue, setTabValue] = useState(0);
  const [sortOrder, setSortOrder] = useState("asc");
  const [markers] = useState<any[]>([{ markers: [], name: "" }]);
  const [clientToEdit, setClientToEdit] = useState<Client | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const { token } = useToken();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [clientToDelete, setClientToDelete] = useState<number | null>(null);
  const [showChatModal, setShowChatModal] = useState<boolean>(false);
  const [currentClient, setCurrentClient] = useState<any>();
  const [refreshed, setRefreshed] = useState<number[]>([]);
  const processedClients = useRef<Set<number>>(new Set());
  const [sortField, setSortField] = useState("name"); // Set initial sort field

  useEffect(() => {
    const clientsList = clients.map(client => ({
      id: client.id,
      name: client.name,
      email: client.email,
      phone: client.phoneNumber,
      progressPercentage: client.progressPercentage,
      lastLoginDtUtc: client.lastLoginDtUtc,
      uiFlowJsonID: client.uiFlowJsonID,
      unreadMessageCount: 0 // Initialize unreadMessageCount to 0
    }));
    setUiUserData(clientsList);
  }, [clients]);


  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const commonOptions = {
    sort: true,
  };

  const generateColumn = (
    name: string,
    label: string,
    options = {},
    sortable: string
  ) => ({
    name,
    label,
    options: {
      customHeadRender: ({ index, ...column }: any) => {
        return (
          <TableCell
            key={index}
            sx={{ py: "8px" }}
            onClick={() => handleSort(name)}>
            <IconButton>
              <InfoIcon />
            </IconButton>
            <Typography sx={{ fontWeight: "700", color: "black" }}>
              {label}
            </Typography>
          </TableCell>
        );
      },
      ...options,
    },
    sortable,
  });

  let columns: any = JsonData.columns
    .map((name: any) => {
      if (name?.flag) {
        return generateColumn(
          name?.value,
          name?.title,
          {
            ...commonOptions,
          },
          (name.sortable = "asc")
        );
      }
      return null;
    })
    .filter((column: any) => column !== null);

  const handleViewCurrUser = (clientId: number) => {
    setSelectedClientId(clientId);
    setShowViewModal(!showViewModal);
  };

  const handleUpdateClient = (updatedClient: Client) => {
    setUiUserData(prevUiUserData =>
      prevUiUserData.map(u => (u.id === updatedClient.id ? {
        id: updatedClient.id,
        name: updatedClient.name,
        email: updatedClient.email,
        phone: updatedClient.phoneNumber,
        progressPercentage: updatedClient.progressPercentage,
        lastLoginDtUtc: updatedClient.lastLoginDtUtc,
        uiFlowJsonID: updatedClient.uiFlowJsonID,
      } : u))
    );
  };

  const handleEditCurrUser = (user: any) => {
    const client: Client = {
      id: user.id,
      name: user.name,
      email: user.email,
      phoneNumber: user.phoneNumber ?? user.phone,
      progressPercentage: user.progressPercentage,
      caseType: "",
      jsonId: "",
      formName: "",
      formVersion: "",
      lastLoginDtUtc: user.lastLoginDtUtc,
      uiFlowJsonID: user.uiFlowJsonID,
    }
    setClientToEdit(client);
    setShowEditModal(true);
  };

  const handleSort = (field: string) => {
    const newSortOrder: any = sortOrder === "asc" ? "desc" : "asc";
    const sortedData: any = [...uiUserData];

    sortedData.sort((a: any, b: any) => {
      if (newSortOrder === "asc") {
        if (field === "progress") {
          return a["progressPercentage"] - b["progressPercentage"];
        } else {
          return a[field]?.localeCompare(b[field]);
        }
      } else {
        if (field === "progress") {
          return b["progressPercentage"] - a["progressPercentage"];
        } else {
          return b[field]?.localeCompare(a[field]);
        }
      }
    });

    setSortOrder(newSortOrder);
    setUiUserData(sortedData);
  };

  const handleChange = (event: any, selectedData: any) => {
    let tempFlag: boolean = !selectedData?.flag;
    let tempJsonData = [...JsonData.columns];
    tempJsonData = tempJsonData.map((columnData: any) => {
      if (selectedData?.value === columnData?.value) {
        return {
          ...columnData,
          flag: tempFlag
        };
      }
      return columnData;
    });

    setDataJsonTemp(tempJsonData);
    localStorage.setItem("dataJson", JSON.stringify(tempJsonData));
  };

  const TablePaginationActions = (props: any) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    const handleFirstPageButtonClick = (event: any) => {
      onPageChange(event, 0);
    };
    const handleBackButtonClick = (event: any) => {
      onPageChange(event, page - 1);
    };
    const handleNextButtonClick = (event: any) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: any) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  const handleClickOpenDeleteDialog = (clientId: number) => {
    setClientToDelete(clientId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setClientToDelete(null);
  };

  const handleDeleteClient = async () => {
    if (clientToDelete === null) return;

    try {
      const axiosInstance = dotnetApiClient();
      const response = await axiosInstance.delete(`/clients/${clientToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 204) {
        setUiUserData(prevUiUserData =>
          prevUiUserData.filter(client => client.id !== clientToDelete)
        );
      } else {
        logErrorFunction("Failed to delete client");
      }
    } catch (error: any) {
      logErrorFunction("Error deleting client:" + error);

      if (error?.response?.status === 401 || error?.response?.status === 406) {
        localStorage.removeItem('billingEntityId');
        navigate('/login');
      }
    } finally {
      setOpenDeleteDialog(false);
      setClientToDelete(null);
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEmulate = (client: ClientType) => {
    navigate("/iframe", { state: { role: 'lawyer', token: token, user: client } });
  };

  const renderName = (customerName: string | undefined) => {
    if (!customerName) return '';
    const temp = customerName.split(" ");
    if (temp.length < 2) return customerName.charAt(0);
    const finalName = temp[0].charAt(0) + temp[1].charAt(0);
    return finalName;
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - uiUserData?.length) : 0;

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  var markerIcon = L.icon({
    iconUrl: MarkerImage,
    iconSize: [60, 60],
    iconAnchor: [60, 60],
    popupAnchor: [10, -56],
  });

  const handleChat = (customer: any) => {

    setShowChatModal(true);
    setCurrentClient(customer);

  };
  ////// Chat only start /// 
  const [loading, setLoading] = useState<{ [key: number]: boolean }>({}); // Loading state for each user

  useEffect(() => {
    if (uiUserData.length === 0) return;

    const processClients = async () => {
      for (const clientData of uiUserData) {
        // Check if the client has already been processed
        if (!processedClients.current.has(clientData.id)) {
          try {
            // Set loading to true for this client
            setLoading(prevLoading => ({ ...prevLoading, [clientData.id]: true }));
            // Mark the client as processed immediately to prevent duplicate processing
            processedClients.current.add(clientData.id);
            // Wait for both API calls to complete and get the unread message count
            const unreadMessageCount = await callApisForClient(clientData);

            // Update the uiUserData with the unreadMessageCount for the processed client
            setUiUserData(prevState =>
              prevState.map(user => user.id === clientData.id ? { ...user, unreadMessageCount } : user)
            );
            // Set loading to false for this client after processing
            setLoading(prevLoading => ({ ...prevLoading, [clientData.id]: false }));
            setRefreshed(prev => [...prev, clientData.id]); // Track processed clients
          } catch (err) {
            console.error(err);
            // Set loading to false in case of an error
            setLoading(prevLoading => ({ ...prevLoading, [clientData.id]: false }));
          }
        }
      }
    };

    processClients();
  }, [uiUserData]);

  // Function to call both APIs using Promise.all and return the unreadMessageCount
  const callApisForClient = async (clientData: any) => {
    try {
      if (clientData?.id) {
        const requestData = { withUserId: clientData?.id };
        // Call both APIs in parallel
        const [chatResponse, messageInfoResponse] = await Promise.all([
          dotnetApiClient().post('/chat', requestData),            // Chat API call
          dotnetApiClient().post('/chat/message/info', requestData) // Message Info API call
        ]);
        // Extract unreadMessageCount from the messageInfoResponse
        const unreadMessageCount = messageInfoResponse.data?.unreadMessageCount;
        return unreadMessageCount;
      }
    } catch (error: any) {
      logErrorFunction("Error fetching data: " + error);
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        localStorage.removeItem('billingEntityId');
        navigate('/login');
      }
      throw error; // Rethrow the error to handle in useEffect
    }
  };

  const updateUnreadCount = (newUnreadCount: number) => {
    setUiUserData(prevState =>
      prevState.map(user =>
        user.id === currentClient?.id ? { ...user, unreadMessageCount: newUnreadCount } : user
      )
    );
  };
  //chat end //
  return (
    <>
      <Box>
        <Box
          className="dashboard filterableColumnTable"
          sx={{
            padding: { md: "20px", xs: "10px" },
            height: 'calc(100vh - 121px)',
            overflowY: 'auto'
          }}>
          <Box sx={{ height: '800px', overflowY: 'auto' }}>
            <CustomTabPanel value={tabValue} index={0} >
              <Box className="filterTableContainer">
                <TableContainer>
                  {uiUserData.length === 0 ? (
                    <Box sx={{ textAlign: "center", marginTop: 4 }}>
                      <EmptyState />
                    </Box>
                  ) : (
                    <Table>
                      <TableHead>
                        <TableRow>
                          {columns?.map((column: any, ind: any) => (
                            <TableCell
                              key={ind}
                              sx={{
                                fontSize: "16px",
                                fontWeight: "700",
                                color: "#757575",
                                cursor: "pointer",
                                py: "8px",
                              }}
                              onClick={(e: any) => {
                                if (
                                  column?.name === "name" ||
                                  column?.name === "email" ||
                                  column?.name === "progress"
                                ) {
                                  handleSort(column?.name);
                                }
                              }}
                            >
                              <Box display="flex" alignItems="center">
                                <span className="customTableHeader">
                                  {column?.name === "id" ? (
                                    <Grid3x3Icon
                                      sx={{ mr: 1, fontSize: "26px" }}
                                    />
                                  ) : column?.name === "name" ? (
                                    <PeopleIcon
                                      sx={{ mr: 1, fontSize: "26px" }}
                                    />
                                  ) : column?.name === "email" ? (
                                    <EmailIcon sx={{ mr: 1, fontSize: "26px" }} />
                                  ) : column?.name === "progress" ? (
                                    <DonutLargeIcon
                                      sx={{ mr: 1, fontSize: "26px" }}
                                    />
                                  ) : column?.name === "phone" ? (
                                    <LocalPhoneIcon
                                      sx={{ mr: 1, fontSize: "26px" }}
                                    />
                                  ) : column?.name === "lastLogin" ? (
                                    <CalendarMonthIcon
                                      sx={{ mr: 1, fontSize: "26px" }}
                                    />
                                  ) : column?.name === "caseType" ? (
                                    <InventoryIcon
                                      sx={{ mr: 1, fontSize: "26px" }}
                                    />

                                  ) : column?.name === "action" ? (
                                    <TuneIcon sx={{ mr: 1, fontSize: "26px" }} />
                                  ) : null}
                                </span>
                                <span>{column?.label}</span>
                                {(column?.name === "name" ||
                                  column?.name === "email" || column?.name === "progress") && (
                                    <span>
                                      <SwapVertIcon
                                        sx={{ mr: 1, fontSize: "26px" }}
                                      />
                                    </span>
                                  )}
                              </Box>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? uiUserData?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          : uiUserData
                        ).map((customer: any) => (
                          <TableRow key={customer.id}>
                            {columns.map((column: any) =>
                              column.name === "name" ? (
                                <TableCell key={column.name} sx={{ py: "8px" }}>
                                  <Tooltip title={customer[column.name]}>
                                    <div
                                      className="td_container"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <span
                                        style={{
                                          height: "30px",
                                          width: "30px",
                                          display: "inline-flex",
                                          borderRadius: "50px",
                                          background: customer?.backgroundColor,
                                          color: "black",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          marginRight: "5px",
                                          fontWeight: "700",
                                          fontSize: "14px",
                                          backgroundColor: "#FFC0CB",
                                        }}
                                      >
                                        {renderName(customer[column.name])}
                                      </span>
                                      {customer[column.name]}
                                    </div>
                                  </Tooltip>
                                </TableCell>
                              ) : column.name === "progress" ? (
                                <TableCell
                                  key={column.progressPercentage}
                                  sx={{
                                    py: "8px",
                                    background:
                                      customer[column.progressPercentage] <= 40
                                        ? "#ffb3ba"
                                        : customer[column.progressPercentage] > 40 &&
                                          customer[column.progressPercentage] <= 70
                                          ? "#ffdfba" : "#baffc9",
                                  }}>
                                  <div>{customer?.progressPercentage || 0}%</div>
                                </TableCell>
                              ) : column.name === "email" ? (
                                <TableCell
                                  key={column.name}
                                  sx={{ cursor: "pointer", py: "8px" }}
                                >
                                  <div className="message_view">
                                    <Tooltip title={customer[column.name]}>
                                      <div className="td_container message_container">
                                        {customer[column.name]}
                                      </div>
                                    </Tooltip>
                                    <IconButton onClick={() => handleChat(customer)} className="unread_count_container" >
                                      {loading[customer.id] ? <CircularProgress disableShrink size="20px" value={25} /> : <TextsmsIcon style={{ color: "#757575" }} />}
                                      {customer?.unreadMessageCount > 0 && <p className="unread_count_badge">  {customer?.unreadMessageCount} </p>}
                                    </IconButton>
                                  </div>
                                </TableCell>
                              ) : column.name === "caseType" ? (
                                <TableCell
                                  key={column.name}
                                  sx={{ cursor: "pointer", py: "8px" }}
                                >
                                  <Tooltip title={customer[column.name]}>
                                    <div className="td_container">
                                      Marrigae AOS
                                    </div>
                                  </Tooltip>
                                </TableCell>
                              ) : column.name === "lastLogin" ? (
                                <TableCell
                                  key={column.name}
                                  sx={{ cursor: "pointer", py: "8px" }}
                                >
                                  <Tooltip title={customer[column.name]}>
                                    <div className="td_container">
                                      {customer?.lastLoginDtUtc
                                        ? moment.utc(customer.lastLoginDtUtc).local().format("YYYY-MM-DD HH:mm:ss")
                                        : "Not signed in yet"}
                                    </div>
                                  </Tooltip>
                                </TableCell>
                              ) : column.name === "action" ? (
                                <TableCell
                                  key={column.name}
                                  sx={{ cursor: "pointer", py: "8px" }}>
                                  <Tooltip title={customer[column.name]}>
                                    <div>
                                      <Button
                                        variant="outlined"
                                        sx={{
                                          width: "98px",
                                          background: "#eff6ff",
                                        }}
                                        onClick={() =>
                                          handleViewCurrUser(customer?.id)
                                        }>
                                        View
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        sx={{
                                          width: "98px",
                                          background: "#eff6ff",
                                        }}
                                        onClick={() =>
                                          handleEditCurrUser(customer)
                                        }>
                                        Edit
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        sx={{
                                          width: "98px",
                                          background: "#eff6ff",
                                        }}
                                        onClick={() =>
                                          handleClickOpenDeleteDialog(customer.id)}>
                                        Delete
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        sx={{
                                          width: "98px",
                                          background: "#eff6ff",
                                        }}
                                        onClick={() =>
                                          handleEmulate(customer)}>
                                        Emulate
                                      </Button>
                                    </div>
                                  </Tooltip>
                                </TableCell>
                              ) : (
                                <TableCell key={column.name} sx={{ py: "8px" }}>
                                  <div className="td_container">
                                    {customer[column.name]}
                                  </div>
                                </TableCell>
                              )
                            )}
                          </TableRow>
                        ))}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            colSpan={columns.length}
                            count={uiUserData?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            slotProps={{
                              select: {
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                                native: true,
                              },
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  )}
                </TableContainer>
                <FooterComponent />
                {/* <ChatComponent /> */}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <div className="container max-w-full h-[600px]">
                <MapContainer
                  center={markers[0]?.markers}
                  zoom={8}
                  scrollWheelZoom={true}
                  className="h-[70vh]"
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {markers?.map((data: any, index: number) => (
                    <Marker key={index} position={data?.markers} icon={markerIcon}>
                      <Popup>{`${data?.name} `}</Popup>
                    </Marker>
                  ))}
                </MapContainer>
              </div>
            </CustomTabPanel>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {JsonData.columns.map((name: any) =>
                name?.title === "Action" ? (
                  <> </>
                ) : (
                  <MenuItem
                    disabled={name?.title === "Name"}
                    key={name?.title}
                    value={name?.title}
                    onClick={(e: any) => handleChange(e, name)}
                  >
                    <Checkbox checked={name?.flag} />
                    <ListItemText primary={name?.title} />
                  </MenuItem>
                )
              )}
            </Menu>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        PaperProps={{
          sx: {
            borderRadius: 3,
            padding: 2,
            width: '400px',
            maxWidth: '100%',
          },
        }}
        TransitionComponent={Fade}
        transitionDuration={300}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'error.main',
          }}
        >
          <WarningIcon sx={{ mr: 1 }} />
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: 'text.secondary', mb: 2 }}>
            Are you sure you want to delete this record? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', paddingX: 3 }}>
          <Button
            onClick={handleCloseDeleteDialog}
            variant="outlined"
            color="inherit"
            sx={{
              color: 'text.secondary',
              borderColor: 'grey.500',
              '&:hover': {
                borderColor: 'grey.700',
                backgroundColor: 'grey.100',
              },
            }}
          >
            No
          </Button>
          <Button
            onClick={handleDeleteClient}
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            sx={{
              '&:hover': {
                backgroundColor: 'error.dark',
              },
            }}
          >
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>

      {showViewModal && (
        <ViewModal
          isViewPopup={showViewModal}
          setIsView={setShowViewModal}
          userId={selectedClientId}
        />
      )}
      {
        showEditModal && (
          <EditClientModal
            open={showEditModal}
            onClose={() => setShowEditModal(false)}
            client={clientToEdit}
            onClientUpdate={handleUpdateClient}
          />
        )
      }
      {
        showChatModal && (
          <ChatDialog
            showChatModal
            onClose={() => setShowChatModal(!showChatModal)}
            currentClient={currentClient}
            setUnreadCount={(newUnreadCount) => updateUnreadCount(newUnreadCount)}
          />
        )
      }
    </>
  );
}

export default FilterableColumnTable;
