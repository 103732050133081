import React from 'react';
import { Modal, Box, TextField, Button, Typography, MenuItem, Select, FormControl, InputLabel, SelectChangeEvent, CircularProgress, Checkbox  } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Client from './client';
import dotnetApiClient from '../../config/dotnetApiClient';
import axios from 'axios';
import { logErrorFunction } from '../../helper/service';

interface AddClientModalProps {
  open: boolean;
  onClose: () => void;
  onAddClient: (client: Client) => void;
  fetchDataFromAPI: () => void;
  setApiCalled: (flag: boolean) => void;

}

const AddClientModal: React.FC<AddClientModalProps> = ({ open, onClose, onAddClient, fetchDataFromAPI, setApiCalled }) => {
  const navigate = useNavigate();

  const validateData: any = typeof localStorage !== 'undefined' ? JSON.parse(localStorage.getItem('supportedCaseTypes') || 'null') : "";

  // Create a function to get the latest version of each form
  const getLatestForms = (dataArray: any[]) => {
    const formMap: Record<string, any> = {};

    dataArray.forEach((data) => {
      const { title, version } = data;

      // If the title is not in the map or current version is greater, update it
      if (!formMap[title] || version > formMap[title].version) {
        formMap[title] = data;
      }
    });

    // Convert formMap back to an array
    return Object.values(formMap);
  };
  // Filter validateData to get the latest version for each form
  const latestForms = getLatestForms(validateData);

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(60, 'Name should not exceed 40 characters')
      .required('Name is required'),
    phoneNumber: Yup.string()
      .matches(/^[0-9]{8,15}$/, 'Phone number should be between 8 and 15 digits')
      .required('Phone number is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    id: Yup.string()
      .required('Form name is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      phoneNumber: '',
      email: '',
      id: '',
      uiFlowJsonID: '',
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {

      setSubmitting(true);
      const client = {
        id: Math.floor(Math.random() * 10000),
        name: values.name,
        email: values.email,
        phoneNumber: values.phoneNumber,
        progressPercentage: 0,
        caseType: '',
        jsonId: '',
        formName: values.id,
        formVersion: '',
        createDtUtc: '',
        uiFlowJsonID: values.uiFlowJsonID,
      };

      const selectedData:any = latestForms?.filter((x: any) => x.id === values.id);
      console.log("selectedData ====>", selectedData);

      const requestData = {
        Name: client.name,
        PhoneNumber: client.phoneNumber,
        Email: client.email,
        uiFlowJsonID: client.formName,
        caseIds: [selectedData[0]?.caseId]
      };

      try {
        const response = await dotnetApiClient().post('/clients', requestData);
        const data = response.data;
        if (response.status === 201) {
          onAddClient(data);
          setApiCalled(false);
          fetchDataFromAPI();
          onClose();
        } else {
          logErrorFunction('Error adding client:' + data);
        }
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status === 409) {
            setFieldError('email', 'Looks like this email is already registered in our records');
          } else if (error?.response?.status === 401 || error?.response?.status === 406) {
            localStorage.removeItem('billingEntityId');
            navigate('/login');
          } else {
            logErrorFunction('Error adding client:' + error.response?.data);
          }
        } else {
          logErrorFunction('Unexpected error:' + error);
          if (error?.response?.status === 401 || error?.response?.status === 406) {
            localStorage.removeItem('billingEntityId');
            navigate('/login');
          }
        }
      } finally {
        setSubmitting(false);
      }
    }
  });

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...modalStyle }}>
        <Typography variant="h6" mb={2}>
          Add New Client
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            label="Client Name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Client Phone Number"
            name="phoneNumber"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Client Email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            fullWidth
            margin="normal"
          />
          <FormControl
            fullWidth
            margin="normal"
            error={formik.touched.id && Boolean(formik.errors.id)}
            variant="outlined"
          >
            <InputLabel id="form-name-label">Case Type</InputLabel>
            <Select
              labelId="form-name-label"
              name="id"
              value={formik.values.id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Case Type"
            >
              <MenuItem value="" disabled></MenuItem>
              {
                latestForms && latestForms.length > 0 && latestForms.map((data: any, index: number) => (
                  <MenuItem key={index} value={data?.id}>{data?.title + ' - ' + data?.version}</MenuItem>
                ))
              }
            </Select>
            {formik.touched.id && formik.errors.id && (
              <Typography variant="caption" color="error">
                {formik.errors.id}
              </Typography>
            )}
          </FormControl>

          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={onClose} sx={{ mr: 1 }} disabled={formik.isSubmitting}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" disabled={formik.isSubmitting}>
              {formik.isSubmitting ? <CircularProgress size={24} /> : 'Add Client'}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default AddClientModal;
