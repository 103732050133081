import React, { useEffect, useState } from "react";
import { ITextFieldStyles, TextField } from '@fluentui/react/lib/TextField';
import { DetailsListLayoutMode, SelectionMode, IColumn } from '@fluentui/react/lib/DetailsList';
import { ShimmeredDetailsList } from '@fluentui/react/lib/ShimmeredDetailsList';
import { DefaultPalette, mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { ActionButton, Icon, IDropdownOption, IIconProps, IStackItemStyles, IStackStyles, IStackTokens, PrimaryButton, Spinner, Stack, TooltipHost } from '@fluentui/react';
import dotnetApiClient from "../../config/dotnetApiClient";
import { useNavigate } from "react-router-dom";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { logErrorFunction } from "../../helper/service";

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  },
});

const stackStyles: IStackStyles = {
  root: {
    background: DefaultPalette.neutralLight,
    width: 520,
  },
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 5,
  padding: 10,
};

const stackItemStyles: IStackItemStyles = {
  root: {
    color: DefaultPalette.themePrimary,
    padding: 5,
  },
};

export interface DetailListProps {
  userId: string;
  isModalSelection: boolean;
  isCompactMode: boolean;
}

export interface IUserDocument {
  udId: string;
  userId: string;
  documentSlug: string;
  dateCreated: string;
  dateDeleted: string;
  originalFileName: string;
  title: string;
  description: number;
}

export const UserDocumentList: React.FC<DetailListProps> = ({
  userId
  // UserId is not used yet in this sample component, the lawyer view will need to use this and call list API or preview API
  // such as /userdocument/user/${userId}/list instead of /userdocument/list
}) => {
  const navigate = useNavigate();
  const [userDocuments, setUserDocuments] = useState<IUserDocument[]>([]);
  const [loading, setLoading] = useState(false);
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const [inputKey, setInputKey] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const baseURL = dotnetApiClient().defaults.baseURL;

  initializeIcons();

  const deleteIcon: IIconProps = { iconName: 'Delete' };
  const downloadIcon: IIconProps = { iconName: 'Download' };
  const narrowTextFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 230 } };

  useEffect(() => {
    _generateDocuments();
  }, []);

  const columns: IColumn[] = [
    {
      key: 'columnFileName',
      name: 'File Name',
      fieldName: 'originalFileName',
      minWidth: 210,
      maxWidth: 350,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
    },
    {
      key: 'columnTitle',
      name: 'Title',
      fieldName: 'title',
      minWidth: 210,
      maxWidth: 350,
      data: 'string',
      isPadded: true,
      isResizable: true,
      onRender: (item: IUserDocument) => {
        return (
          <TooltipHost content={`${item.description}`}>
            <span>{item.title}</span>
          </TooltipHost>
        )
      }
    },
    {
      key: 'columnDownload',
      name: 'Download',
      fieldName: 'download',
      minWidth: 40,
      maxWidth: 90,
      isResizable: true,
      data: 'number',
      isPadded: true,
      onRender: (item: IUserDocument) => {
        return <ActionButton iconProps={downloadIcon} onClick={() => onDownload(item)}></ActionButton>;
      },
    },
    {
      key: 'columnDelete',
      name: 'Delete',
      fieldName: 'options',
      minWidth: 40,
      maxWidth: 90,
      isResizable: false,
      isPadded: true,
      onRender: (item: IUserDocument) => {
        return (
          <ActionButton iconProps={deleteIcon} allowDisabledFocus disabled={false} checked={false} onClick={() => _handleFileDelete(item)}>
          </ActionButton>
        )
      }
    },
  ];

  function onDownload(doc: IUserDocument) {
    const linkUrl = baseURL + "/userdocument/preview/" + doc.udId;
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = doc.originalFileName;
    link.href = linkUrl;
    link.click();
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleFileRemove = () => {
    let randomString = Math.random().toString(36);
    setFile(null);
    setInputKey(randomString);
  };

  async function _generateDocuments() {
    setLoading(true);
    try {
      await dotnetApiClient().get<IUserDocument[]>(`/userdocument/list`)
        .then((response) => {
          setUserDocuments(response.data);
          setLoading(false);
        });
    } catch (error: any) {
      logErrorFunction(error);

      if (error?.response?.status === 401 || error?.response?.status === 406) {
        localStorage.removeItem('billingEntityId');
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  }

  async function _handleFileUpload() {
    if (file) {
      setFileIsUploading(true);
      const formData = new FormData();
      formData.append('Document', file);
      formData.append('title', title);
      formData.append('description', description);

      try {
        await dotnetApiClient(true).post(`/userdocument/upload`, formData)
          .then((response) => {
            _generateDocuments();
          });
      } catch (error: any) {
        logErrorFunction(error);
        if (error?.response?.status === 401 || error?.response?.status === 406) {
          localStorage.removeItem('billingEntityId');
          navigate('/login');
        }
      } finally {
        setFileIsUploading(false);
        setFile(null);
        setTitle('');
        setDescription('');
      }
    }
  }

  async function _handleFileDelete(file: IUserDocument) {
    if (file) {
      setLoading(true);
      try {
        await dotnetApiClient(true).delete(`/userdocument/${file.udId}`)
          .then((response) => {
            _generateDocuments();
          });
      } catch (error: any) {
        const errorMessage = error;

        if (error?.response?.status === 401 || error?.response?.status === 406) {
          localStorage.removeItem('billingEntityId');
          navigate('/login');
        }
      }
    }
  }

  const onChangeTitle = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      if (!newValue || newValue.length <= 25) {
        setTitle(newValue || '');
      }
    },
    [],
  );

  const onChangeDescription = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      if (!newValue || newValue.length <= 800) {
        setDescription(newValue || '');
      }
    },
    [],
  );

  return (
    <div>
      <div>
        <Stack enableScopedSelectors styles={stackStyles} tokens={itemAlignmentsStackTokens}>
          <Stack.Item align="auto" styles={stackItemStyles}>
            <input id="file" type="file" accept=".png,.jpeg,.pdf" onChange={handleFileChange} key={inputKey || ''} />
            {file && <ActionButton iconProps={deleteIcon} allowDisabledFocus disabled={false} checked={false} onClick={() => handleFileRemove()}>
            </ActionButton>}
          </Stack.Item>
          <Stack.Item align="auto" styles={stackItemStyles}>
            <TextField
              label="Title"
              value={title}
              onChange={onChangeTitle}
              styles={narrowTextFieldStyles}
            />
          </Stack.Item>
          <Stack.Item align="auto" styles={stackItemStyles}>
            <TextField
              label="Description"
              multiline
              rows={3}
              value={description}
              onChange={onChangeDescription} />
          </Stack.Item>

          {fileIsUploading && <Spinner className='loadingSpinner' label='Loading...' />}

          {file && !fileIsUploading &&
            <Stack.Item align="end" styles={stackItemStyles}>
              <PrimaryButton text="Upload File" onClick={_handleFileUpload} allowDisabledFocus disabled={file === null} />
            </Stack.Item>
          }
        </Stack>
      </div>
      <div>
        <ShimmeredDetailsList
          items={userDocuments}
          columns={columns}
          selectionMode={SelectionMode.none}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          enableShimmer={loading}
          isHeaderVisible={true}
          styles={{ root: { cursor: 'pointer' } }}
        />
      </div>
    </div>
  );
}

export default UserDocumentList;