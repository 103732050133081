import React from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Typography, FormHelperText } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Client from "../client";

interface ClientFormProps {
  client: Client | null;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleFormNameChange?: (event: SelectChangeEvent<string>) => void; // Optional
}

const ClientForm: React.FC<ClientFormProps> = ({ client, handleChange, handleFormNameChange }) => {
  const validateData: any = typeof localStorage !== 'undefined' ? JSON.parse(localStorage.getItem('supportedCaseTypes') || 'null') : "";

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(60, 'Name should not exceed 60 characters')
      .required('Name is required'),
    phoneNumber: Yup.string()
      .matches(/^[0-9]{8,15}$/, 'Phone number should be between 8 and 15 digits')
      .required('Phone number is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: client?.name || '',
      phoneNumber: client?.phoneNumber || '',
      email: client?.email || '',
      uiFlowJsonID: client?.uiFlowJsonID || '',
      progressPercentage: client?.progressPercentage || 0, 
    },
    validationSchema,
    onSubmit: (values) => {
      const dataToSubmit = {
        ...values,
        progressPercentage: client?.progressPercentage || 0, 
      };
    },
  });

  
  // Create a function to get the latest version of each form
  const getLatestForms = (dataArray: any[]) => {
    const formMap: Record<string, any> = {};

    dataArray.forEach((data) => {
      const { title, version } = data;

      // If the title is not in the map or current version is greater, update it
      if (!formMap[title] || version > formMap[title].version) {
        formMap[title] = data;
      }
    });

    // Convert formMap back to an array
    return Object.values(formMap);
  };

  // Filter validateData to get the latest version for each form
  const latestForms = getLatestForms(validateData);

  return (
    <>
      <TextField
        label="Client Name"
        name="name"
        value={formik.values.name}
        onChange={e => {
          formik.handleChange(e);
          handleChange(e);
        }}
        onBlur={formik.handleBlur}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Client Phone Number"
        name="phoneNumber"
        value={formik.values.phoneNumber}
        onChange={e => {
          formik.handleChange(e);
          handleChange(e);
        }}
        onBlur={formik.handleBlur}
        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Client Email"
        name="email"
        value={formik.values.email}
        onChange={handleChange}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
        margin="normal"
      />
      <FormHelperText>Email address cannot be edited.</FormHelperText>

      {handleFormNameChange && (
        <FormControl fullWidth margin="normal">
          <InputLabel id="form-name-label">Form Name</InputLabel>
          <Select
            labelId="form-name-label"
            name="uiFlowJsonID"
            value={formik.values.uiFlowJsonID}
            onChange={e => {
              formik.handleChange(e);
              handleFormNameChange(e as SelectChangeEvent<string>);
            }}
            onBlur={formik.handleBlur}
          >
             {
                latestForms && latestForms.length > 0 && latestForms.map((data: any, index: number) => (
                  <MenuItem key={index} value={data?.id}>{data?.title + ' - ' + data?.version}</MenuItem>
                ))
              }
          </Select>
          {formik.touched.uiFlowJsonID && formik.errors.uiFlowJsonID && (
            <Typography variant="caption" color="error">
              {formik.errors.uiFlowJsonID}
            </Typography>
          )}
        </FormControl>
      )}
      <input
        type="hidden"
        name="progressPercentage"
        value={formik.values.progressPercentage}
      />
    </>
  );
};

export default ClientForm;
