import dotnetApiClient from '../../../config/dotnetApiClient';
import { logErrorFunction } from '../../../helper/service';
import Client from '../client';

export const updateClient = async (client: Client, token: string) => {
  const requestData = {
    Id: client.id,
    Name: client.name,
    PhoneNumber: client.phoneNumber,
    Email: client.email,
    UIFlowJsonID: client.uiFlowJsonID,
    progressPercentage: client.progressPercentage,
  };

  try {
    const response = await dotnetApiClient().put(`/clients/${client.id}`, requestData);

    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}, Message: ${response.data?.message}`);
    }

    return response.data;
  } catch (error) {
    logErrorFunction('Error updating client:' + error);
    throw error;
  }
};
