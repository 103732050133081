// src/components/Loader/Loader.js
import React from 'react';

const Loader: React.FC = () => {
  return (
    <div style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      color: '#fff',
      padding: '20px',
      borderRadius: '8px'
    }}>
      Loading...
    </div>
  );
};

export default Loader;
