// const lawFirmLogos: { [key: string]: string } = {
//   "1": "/assets/logos/logo_1.png", 
//   "2": "/assets/logos/logo_2.png", 
//   "3": "/assets/logos/logo_2.png", 
//   "5": "/assets/logos/logo_3.png", 
//   "6": "/assets/logos/logo_4.png",
//   "4": "/assets/logos/logo_6.png",
// };
  
//   export default lawFirmLogos;

const lawFirmLogos: { [key: string]: string } = (() => {
  const env = process.env.NODE_ENV;

  if (env === "development") {
    return {
      "1": "/assets/logos/local/logo_1.png",
      "2": "/assets/logos/local/logo_2.png",
      "3": "/assets/logos/local/logo_3.png",
      "5": "/assets/logos/local/logo_3.png",
      "9": "/assets/logos/prod/logo_1.png",
      "11": "/assets/logos/prod/logo_2.png",
      "12": "/assets/logos/prod/logo_3.png",
      "6": "/assets/logos/prod/logo_4.png",
    };
  } else if (env === "production") {
    return {
      "1": "/assets/logos/local/logo_1.png",
      "2": "/assets/logos/local/logo_2.png",
      "3": "/assets/logos/local/logo_3.png",
      "5": "/assets/logos/local/logo_3.png",
      "9": "/assets/logos/prod/logo_1.png",
      "11": "/assets/logos/prod/logo_2.png",
      "12": "/assets/logos/prod/logo_3.png",
      "6": "/assets/logos/prod/logo_4.png",
    };
  } else {
    return {
      "1": "/assets/logos/local/logo_1.png",
      "2": "/assets/logos/local/logo_2.png",
      "3": "/assets/logos/local/logo_3.png",
      "5": "/assets/logos/local/logo_3.png",
      "6": "/assets/logos/local/logo_4.png",
      "9": "/assets/logos/prod/logo_1.png",
      "11": "/assets/logos/prod/logo_2.png",
      "12": "/assets/logos/prod/logo_3.png",
    };
  }
})();

export default lawFirmLogos;