import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginForm from './components/LoginForm/LoginForm';
import CodePage from './components/CodePage/CodePage';
import IframePage from './components/IframePage/IframePage';
import UserDocumentList from "./components/UserDocuments/index";
import Client from './components/client';
import { useToken } from './context/TokenContext';
import './App.css';

const ProtectedRoute: React.FC<{ element: JSX.Element, redirectTo: string }> = ({ element, redirectTo }) => {
  const { token } = useToken();
  return token ? element : <Navigate to={redirectTo} replace />;
};

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="/code" element={<CodePage />} />
        <Route path="/test" element={<UserDocumentList userId="1" isModalSelection={false} isCompactMode={false} />} />
        <Route path="/iframe" element={<ProtectedRoute element={<IframePage />} redirectTo="/" />} />
        <Route path="/client" element={<ProtectedRoute element={<Client />} redirectTo="/" />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default App;
