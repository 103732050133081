import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Loader from "../Loader/Loader";
import { logFunction } from "../../helper/service";

const IframePage: React.FC = () => {
  const location = useLocation();
  const state = location.state || {};
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [loading, setLoading] = useState(true);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const billingEntityId =
    state?.billingEntityId || localStorage.getItem("billingEntityId");
  const lawyerData: any = JSON.parse(
    localStorage.getItem("lawyerData") || "{}"
  );

  let iframeUrl = window.FORM_UI_URL;

  iframeUrl = iframeUrl.trim();
  if (!iframeUrl) {
    throw new Error("Iframe URL is not set in the configuration");
  }

  useEffect(() => {
    if (!billingEntityId) {
      logFunction("billingEntityId is undefined or missing");
    } else {
      logFunction("Billing Entity ID:" + billingEntityId);
    }
  }, [billingEntityId]);

  useEffect(() => {
    const handleIframeLoadedMessage = (event: MessageEvent) => {
      if (event.data.type === "setLocalStorage") {
        localStorage.setItem(event.data.key, event.data.value);
      }

      if (event.origin !== new URL(iframeUrl).origin) {
        return;
      }

      if (event.data.type === "iframeLoaded") {
        iframeRef.current?.contentWindow?.postMessage(
          { type: "UPDATE_USER_ID", uiFlowJsonId: state?.user?.uiFlowJsonID },
          "*" // Replace with the specific origin of the parent window for better security
        );

        setLoading(false);
        iframeRef.current?.contentWindow?.postMessage(
          {
            authToken: state.token,
            user: state.user,
            role: state.role,
            billingEntityId,
            fromIframe: true,
            lawyer_data: lawyerData,
          },
          iframeUrl
        );
      }
    };

    const handleRedirectMessage = (event: MessageEvent) => {
      if (event.data.type === "REDIRECT") {
        setLoading(true);
        const redirectUrl = event.data.url;
        if (redirectUrl) {
          setTimeout(() => {
            window.location.href = redirectUrl.trim();
          }, 500);
        }
      }
    };

    const handleLogoMessage = (event: MessageEvent) => {
      if (event.origin !== new URL(iframeUrl).origin) {
        return;
      }
      if (event.data.type === "UPDATE_LOGO") {
        setLogoUrl(event.data.logoUrl);
      }
    };

    window.addEventListener("message", handleRedirectMessage);
    window.addEventListener("message", handleIframeLoadedMessage);
    window.addEventListener("message", handleLogoMessage);

    return () => {
      window.removeEventListener("message", handleIframeLoadedMessage);
      window.removeEventListener("message", handleRedirectMessage);
      window.removeEventListener("message", handleLogoMessage);
    };
  }, [state, iframeUrl, billingEntityId]);

  // In IframePage (parent)
  return (
    <div>
      {loading && <Loader />}
      {logoUrl && (
        <img src={logoUrl} alt="Law Firm Logo" style={{ height: "50px" }} />
      )}
      <iframe
        src={iframeUrl}
        ref={iframeRef}
        key={`${state.token}-${billingEntityId}`}
        style={{ width: "100%", height: "100vh", border: "none" }}
      ></iframe>
    </div>
  );
};

export default IframePage;
