import React from "react";
import { Link } from "react-router-dom";

export default function FooterComponent() {
  return (
    <div className="footer-line md:text-left  text-center p-[10px] md:pt-[20px] md:pb-[25px] md:px-8 fixed bottom-0  w-full md:w-[calc(100%_-_240px)] md:flex block items-center justify-between">
      <span className="text-sm text-[#9CA3AF]">
        © 2024 Legal Case App : Lawyer App | All rights reserved
      </span>
    </div>
  );
}
