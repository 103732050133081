// ChatComponent.tsx
import React from 'react';
import type { StreamChat } from 'stream-chat';
import { useCreateChatClient, Chat, Channel, ChannelHeader, MessageInput, MessageList, Thread, Window } from 'stream-chat-react';

import 'stream-chat-react/dist/css/v2/index.css';

interface ChatComponentProps {
  apiKey: string;
  userId: string;
  channelId: string;
  userToken: string;
  userData: { id: string };
}

const ChatDialogComponent: React.FC<ChatComponentProps> = ({ apiKey, channelId, userToken, userData }) => {
  let chatClient: StreamChat | null = null;

  chatClient = useCreateChatClient({
    apiKey: apiKey,
    tokenOrProvider: userToken,
    userData: userData,
  });
  // chatClient = useCreateChatClient({
  //   apiKey: "awg3h7k3fdgv",
  //   tokenOrProvider: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiY2xpZW50MSJ9.mtthLdYBi-IGzVz5MjgldOt0eO0aC0IP767GxA5nk9U',
  //   userData: { id: 'client1' },
  // });

  if (!chatClient) return <div>Loading chat client...</div>;

  const channel = chatClient.channel('messaging', channelId);

  return (
    <Chat client={chatClient}>
      {channel && (
        <Channel channel={channel}>
          <Window>
            <ChannelHeader />
            <MessageList />
            <MessageInput />
          </Window>
          <Thread />
        </Channel>
      )}
    </Chat>
  );
};

export default ChatDialogComponent;