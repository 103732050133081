import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, SelectChangeEvent } from '@mui/material';
import ClientForm from './ClientForm';
import { modalStyle } from './styles';
import { updateClient as updateClientAPI } from './apiClient';
import Client from '../client';
import { useToken } from '../../../context/TokenContext';
import { logErrorFunction } from '../../../helper/service';

interface EditClientModalProps {
  open: boolean;
  onClose: () => void;
  client: Client | null;
  onClientUpdate: (arg0: Client) => void;
}

const EditClientModal: React.FC<EditClientModalProps> = ({ open, onClose, client, onClientUpdate }) => {
  const { token } = useToken(); 
  const [loading, setLoading] = useState(false);
  const [editedClient, setEditedClient] = useState<Client | null>(client);

  useEffect(() => {
    setEditedClient(client);
  }, [client]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (editedClient) {
      setEditedClient(prev => (prev ? { ...prev, [name]: value } : prev));
    }
  };

  const handleFormNameChange = (event: SelectChangeEvent<string>) => {
    if (editedClient) {
      setEditedClient(prev => (prev ? { ...prev, uiFlowJsonID: event.target.value } : prev));
    }
  };

  const handleSubmit = async () => {
    if (!editedClient) return;

    setLoading(true);

    try {
      if (token) {
        const updatedClient = await updateClientAPI(editedClient, token); 
        if (updatedClient) {
          onClose();
          onClientUpdate(updatedClient);
        }
      } else {
        logErrorFunction('Token is not available');
      }
    } catch (error) {
      logErrorFunction('Error updating client:'+ error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...modalStyle }}>
        <Typography variant="h6" mb={2}>
          Edit Client
        </Typography>
        <ClientForm 
          client={editedClient} 
          handleChange={handleChange} 
          handleFormNameChange={handleFormNameChange} 
        />
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button onClick={onClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} disabled={loading}>
            {loading ? 'Updating...' : 'Update'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditClientModal;
